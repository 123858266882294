import { banner, doa } from "../images/index";

const Header = () => {
  return (
    <header>
      <div className="name">DHANIEL****</div>
      <div className="news">DANIEL ADEKOYA+++</div>
      <div className="about">
        <div className="about_name">FRONTEND ENGINEER</div>
        <div className="about_content">
          <div className="about_content_text1">
            A results-driven Front-End Engineer with four years of experience,
            specializing in UI design, web development and mobile development. A
            proven track record of collaborating cross-functionally with
            technical teams and stakeholders across all phases of the software
            development lifecycle.
          </div>
          <div></div>
        </div>
      </div>
      <div className="banner">
        <img src={banner} alt="" />
        <img className="logo" src={doa} alt="" srcset="" />
      </div>
    </header>
  );
};

export default Header;
