import {
  ada,
  bot,
  hpsb,
  pmb,
  vue,
  mrt,
  kc,
  propkip,
  nasida,
  agency,
  recital,
  exchange,
} from "../images/index";
const Work = () => {
  const works = [
    {
      title: "Vue Nav UI",
      desc: "This is a custom navbar vuejs package.",
      link: "https://dhanielcodes.github.io/vue-navigation-ui/",
      type: "VUE",
      img: vue,
      side: true,
    },
    {
      title: "TM Agency",
      desc: "It's the ultimate solution designed to deliver efficiency, security, and seamlessness to your agency banking experience.",
      link: "https://agencybanking.tm30.net/",
      type: "REACT/VITE",
      img: agency,
    },
    {
      title: "MRT Management Tool",
      desc: "It used by organisations to measure, review and track an employee's performance.",
      link: "https://mrtstaff.com/",
      type: "VUE",
      img: mrt,
    },
    {
      title: "Recital Finance",
      desc: "Supercharged Payment and Financial Operations Infrastructure for Growing Businesses",
      link: "https://recital.finance/",
      type: "REACT/NEXT",
      img: recital,
      side: true,
    },
    {
      title: "Propkip",
      desc: "This is a service provision software, get your quick service easily e.g laundry, plumbing etc.",
      link: "https://www.propkip.com/",
      type: "VUE",
      img: propkip,
    },
    {
      title: "Nasida (NASARAWA INVESTMENT AND DEVELOPMENT AGENCY) Website",
      desc: "NASIDA is the Nasarawa Investment and Development Agency, created through an act of the Nasarawa State House of Assembly to initiate, promote, facilitate and coordinate investments in Nasarawa State, whether it be public private partnerships, privatization, concessions or commercialization of state owned assets.",
      link: "https://www.nasida.na.gov.ng/",
      type: "VUE",
      img: nasida,
    },
    {
      title: "Payarena Exchange Monitoring Dashboard",
      desc: "The transaction monitoring dashboard is a data analytics monitoring solution that enable tracking of key performance metrics and easy visualization of transactional data sets for various category of users. It gives a high-level view of mission critical metrics and also enable download of selected data sets for further detailed analysis by the users.",
      link: "https://pxmonitoring.up-ng.com/",
      type: "REACT",
      img: exchange,
    },

    {
      title: "Boton",
      desc: "A simple CSS library for getting nice styled custom buttons.",
      link: "https://boton.vercel.app/",
      type: "CSS",
      img: bot,
      side: true,
    },
  ];
  return (
    <>
      <div className="section">
        <section>
          <div className="title">SIDE WORKS</div>
          <div className="section_works">
            {works
              ?.filter((item) => item?.side)
              ?.map((work) => {
                return (
                  <div className="work">
                    <div className="work_img">
                      <img src={work?.img} alt="" />
                    </div>
                    <div className="work_desc">
                      <div className="work_desc_title">{work?.title}</div>
                      <div className="work_desc_text">{work?.desc}</div>
                      <div className="work_desc_link">
                        <a href={work?.link} target="_blank" rel="noreferrer">
                          {work?.link}
                        </a>
                        <div>{work?.type}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
        {/* <div className="hobby">
        <div className="hobby_title">My Hobbies</div>
        <div className="hobby_list">
          <div className="hobby_item">
            <img style={{ width: "20px" }} src={dot} alt="" srcset="" />
            Basketball
          </div>
        </div>
      </div> */}
      </div>
      <div className="section">
        <section>
          <div className="title">COMPANY WORKS</div>
          <div className="section_works">
            {works
              ?.filter((item) => !item?.side)
              ?.map((work) => {
                return (
                  <div className="work">
                    <div className="work_img">
                      <img src={work?.img} alt="" />
                    </div>
                    <div className="work_desc">
                      <div className="work_desc_title">{work?.title}</div>
                      <div className="work_desc_text">{work?.desc}</div>
                      <div className="work_desc_link">
                        <a href={work?.link} target="_blank" rel="noreferrer">
                          {work?.link}
                        </a>
                        <div>{work?.type}</div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </section>
        {/* <div className="hobby">
        <div className="hobby_title">My Hobbies</div>
        <div className="hobby_list">
          <div className="hobby_item">
            <img style={{ width: "20px" }} src={dot} alt="" srcset="" />
            Basketball
          </div>
        </div>
      </div> */}
      </div>
    </>
  );
};

export default Work;
