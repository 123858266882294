const Navbar = () => {
    return (
        <nav>
            <div className="name">
                DANIEL ADEKOYA
            </div>
        </nav>
    );
}

export default Navbar;