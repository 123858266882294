import './App.css';
import './index.css';
import { Navbar } from "./components"
import { Header, Works, Experience, Contact } from "./views"
function App() {
  return (
    <div className="App">
      <Navbar/>
      <div className='website'>
        <Header/>
        <Works/>
        <Experience/>
      </div>
      <Contact/>

    </div>
  );
}

export default App;
