import { da } from "../images/index";

const Experience = () => {
  const experiences = [
    {
      title: "Mid Level Frontend Engineer",
      place: "TM30",
      period: "October 2022 - Present",
      type: "Onsite - Full time",
      site: "https://tm30.net/",
      mode: "Full Time",
      current: true,
    },
    {
      title: "Junior Frontend Engineer",
      place: "RARA",
      period: "April 2021 - June 2021",
      type: "Remote - Contract",
      site: "https://rara.social/",
      mode: "Part Time",
    },
    {
      title: "Junior Frontend Engineer",
      place: "Dork",
      period: "January 2021 - October 2022",
      type: "Remote - Full time",
      site: "https://www.instagram.com/dorkdiscovery/",
      mode: "Full Time",
    },

    {
      title: "Frontend Developer Intern",
      place: "HNG",
      period: "April 2020 - August 2020",
      type: "Remote",
      site: "https://twitter.com/hnginternship",
      mode: "Internship",
    },
  ];
  return (
    <main>
      <div className="title">EXPERIENCE</div>
      <div className="experiences">
        {experiences?.map((experience) => {
          return (
            <div className="experience">
              {experience?.current && (
                <div className="experience_desc_link">
                  <div>Currently Here</div>
                </div>
              )}
              <br />
              <img className="experience_img" src={da} alt="" />
              <div className="experience_desc">
                <div className="experience_desc_title">{experience?.title}</div>
                <div
                  style={{
                    margin: "0px 0px 20px 0",
                    fontSize: "20px",
                    padding: "4px 10px",
                    border: "1px solid",
                    width: "fit-content",
                  }}
                >
                  {experience?.mode}
                </div>

                <div className="experience_desc_link">
                  <a href={experience?.site}>{experience?.place}</a>
                  <div>{experience?.type}</div>
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </main>
  );
};

export default Experience;
